import React, { useState } from 'react';
import Headers from '../components/Header';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Form } from 'react-bootstrap';
import Hero from '../Images/hero.jpg';
import { Zoom, Fade } from 'react-reveal';
import TextField from '@mui/material/TextField';
import { IoChevronBackCircleOutline } from 'react-icons/io5';


const Finalform = () => {
  const navigate = useNavigate();
  const [noOfLeads, setNoOfLeads] = useState('');
  const [loading, setLoading] = useState(false);
  const [minDays, setMinDays] = useState('');
  const [maxDays, setMaxDays] = useState('');
  const [errors, setErrors] = useState({}); 
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [number, setNumber] = useState('');
  const [region, setRegion] = useState('');
  const [country, setCountry] = useState('');
  const [panIndia, setPanIndia] = useState('');
  const [brand, setBrand] = useState('');
  const [websiteURL, setWebsiteURL] = useState('');
  const [noLead, setNoLead] = useState('');

  const handleSubmit = () => {
    if (validateForm()) {
      sessionStorage.setItem('name', name);
      sessionStorage.setItem('email', email);
      sessionStorage.setItem('number', number);
      sessionStorage.setItem('region', region);
      sessionStorage.setItem('country', country);
      sessionStorage.setItem('panIndia', panIndia);
      sessionStorage.setItem('brand', brand)
      sessionStorage.setItem('websiteURL', websiteURL)
      sessionStorage.setItem('Lead', noLead)
      sessionStorage.setItem('noOfLeads', noOfLeads)
      sessionStorage.setItem('maxDays', maxDays)
      sessionStorage.setItem('minDays', minDays)
      navigate('/appointment');
    }
  };



  const validateForm = () => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const contactRegex = /^\d+$/;

    if (!name.trim()) {
      errors.fullName = 'Full Name is required';
    }

    if (!email.trim()) {
      errors.email = 'Email is required';
    } else if (!emailRegex.test(email)) {
      errors.email = 'Invalid email format';
    }

    if (!number.trim()) {
      errors.contactNo = 'Contact Number is required';
    } else if (!contactRegex.test(number)) {
      errors.contactNo = 'Contact Number must contain only numbers';
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newErrors = { ...errors };
    if (name === 'fullName') {
      setName(value);
      delete newErrors['fullName'];
    } else if (name === 'email') {
      setEmail(value);
      delete newErrors['email'];
    } else if (name === 'contactNo') {
      setNumber(value);
      delete newErrors['contactNo'];
    }
    setErrors(newErrors);
  };

  const handleQueryChange = (e) => {
    const selectedOption = e.target.value;
    switch (selectedOption) {
      case '30':
        setNoOfLeads('30');
        setMinDays('30');
        setMaxDays('90');
        break;
      case '50':
        setNoOfLeads('50');
        setMinDays('60');
        setMaxDays('90');
        break;
      case '100':
        break;
      default:
    }
  };
  

  
  
  return (
  <div>
      <Headers />
      <div style={{backgroundColor:'#E6EDF3'}}>
      <div className="container py-3" style={{ backgroundColor: '#E9F0F6' }}>
        <div className="formBoxsh">
          <div className="container">
            <Row>
              <Col xs={5} style={{ padding: '0px', flex: '0 0 auto', width: '41.66666667%' }} className="FormImages">
                <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                  <img alt='imageRac' src={Hero} style={{ width: '95%', borderRadius: '10px 10px 10px 10px', marginTop: '10px', height: '445px' }} />
                </div>
              </Col>
              <Col xs={12} md={7} style={{ padding: '0px', position: 'relative' }}>
                <div className='mainTopic'
                  style={{
                    width: '55%',
                    height: '20%',
                    borderRadius: '0px 10px 10px 0px',
                    border: '7px solid #00A0E3',
                    marginTop: '10px',
                    fontWeight: '600',
                    backgroundColor: '#00A0E3',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Zoom right>
                    <h5  style={{ color: '#ffff', fontFamily: 'Lato, sans-serif', margin: '0px', fontWeight: '600' }}>One step to get quality leads</h5>
                  </Zoom>
                </div>
                <Link to="/selectservices"><IoChevronBackCircleOutline className='backButton' size={40} style={{ position: 'absolute', right: '5%', top: '2%', color: 'red' }} /></Link>
                
                  <div className="calenderHeight" style={{ marginTop: '25px', display: 'flex', justifyContent: 'center' }}>
                    <div className="container">
                      {/* Personal Details */}
                      <Row>
                        <Col xs={12} md={4}>
                          <Form.Group>
                            <Form.Label>Full Name</Form.Label>
                            <Form.Control
                              type="text"
                              name="fullName"
                              value={name}
                              onChange={handleChange}
                              isInvalid={!!errors.fullName} />
                            <Form.Control.Feedback type="invalid">{errors.fullName}</Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col xs={12} md={4}>
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="email"
                            name="email"
                            onChange={handleChange}
                            isInvalid={!!errors.email}
                          />
                          <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                        </Col>
                        <Col xs={12} md={4}>
                          <Form.Label>Contact Number</Form.Label>
                          <Form.Control
                            type="number"
                            name="contactNo"
                            value={number}
                            onChange={handleChange}
                            isInvalid={!!errors.contactNo} />
                          <Form.Control.Feedback type="invalid">{errors.contactNo}</Form.Control.Feedback>
                        </Col>
                      </Row>
                      {/* Region area */}
                      <Row className="mt-4">
                        <Col xs={12} md={4}>
                          <Form.Select name="region" style={{ height: '55px' }} className='inputBoxes' value={region}  onChange={(e)=> setRegion(e.target.value)}>
                            <option>Select Region</option>
                            <option value="India">India</option>
                            <option value="Asia Pacific">Asia Pacific</option>
                            <option value="US & Canada">US & Canada</option>
                            <option value="Europe">Europe</option>
                            <option value="Middle East">Middle East</option>
                            <option value="Africa">Africa</option>
                          </Form.Select>
                        </Col>
                        <Col xs={12} md={4}>
                          <Form.Select name="country" style={{ height: '55px' }} className='inputBoxes' value={country}  onChange={(e)=> setCountry(e.target.value)}>
                            <option>Select country</option>
                            <option value="India">India</option>
                          </Form.Select>
                        </Col>
                        <Col xs={12} md={4}>
                          <Form.Select name="subCountry" style={{ height: '55px' }} className='inputBoxes' value={panIndia}  onChange={(e)=> setPanIndia(e.target.value)}>
                            <option>Pan india</option>
                            <option value="Andhra Pradesh">Andhra Pradesh</option>
                            <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                            <option value="Assam">Assam</option>
                            <option value="Bihar">Bihar</option>
                            <option value="Chhattisgarh">Chhattisgarh</option>
                            <option value="Goa">Goa</option>
                            <option value="Gujarat">Gujarat</option>
                            <option value="Haryana">Haryana</option>
                            <option value="Himachal Pradesh">Himachal Pradesh</option>
                            <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                            <option value="Tamil Nadu">Tamil Nadu</option>
                            <option value="Kerala">Kerala</option>
                            <option value="Punjab">Punjab</option>
                          </Form.Select>
                        </Col>
                      </Row>
                      {/* Area Name */}
                      <Row className="mt-4">
                        <Col xs={12} md={4}>
                          <TextField  name="brandName" label="Brand Name" variant="outlined" style={{ width: '100%',  }} className='inputBoxes'  value={brand}  onChange={(e)=> setBrand(e.target.value)} />
                        </Col>
                        <Col xs={12} md={4}>
                          <TextField name="websiteURL" label="Website url" variant="outlined" style={{ width: '100%',}} className='inputBoxes'  value={websiteURL}  onChange={(e)=> setWebsiteURL(e.target.value)}  />
                        </Col>
                        <Col xs={12} md={4}>
                          <Form.Select style={{ height: '55px' }}  onChange={handleQueryChange}>
                            <option>No.of Queries</option>
                            <option value="30">30</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="250">250</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                          </Form.Select>
                        </Col>
                      </Row>
                      {/* No of lead */}
                      <Row className="mt-4">
                        <Col xs={12} md={4}>
                          <Form.Group>
                            <Form.Label>No. of Leads</Form.Label>
                            <Form.Control
                              type="text"
                              style={{ borderColor: '#C4C4C4', height: '45px', backgroundColor: '#ececec' }}
                              value={noOfLeads}
                              readOnly
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={12} md={4}>
                          <Form.Group>
                            <Form.Label>Minimum Days</Form.Label>
                            <Form.Control
                              type="text"
                              style={{ borderColor: '#C4C4C4', height: '45px', backgroundColor: '#ececec' }}
                              value={minDays}
                              readOnly
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={12} md={4}>
                          <Form.Group>
                            <Form.Label>Maximum Days</Form.Label>
                            <Form.Control
                              type="text"
                              style={{ borderColor: '#C4C4C4', height: '45px', backgroundColor: '#ececec' }}
                              value={maxDays}
                              readOnly
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '15px 0px 15px 0px' }}>
                        <button className='button-47' onClick={handleSubmit} disabled={loading}>
                          {loading ? (
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          ) : (
                            <>PROCESS</>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
            
              </Col>
            </Row>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default Finalform;



